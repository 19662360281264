/* .body{
    font-family: "Open Sans",sans-serif;
    font-size: 16px;
    line-height: 25px;
    text-decoration: none solid rgb(119,119,119);
    color: #777777;
    word-wrap: break-word;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 1200px;
    margin: 30px auto;
    background-color: white;
}

h1 {
    text-align: center;
    color: #2c3e50;
}

p {
    margin-bottom: 20px;
}

.highlight {
    font-weight: bold;
    color: #2980b9;
}

ul {
    margin: 20px 0;
    padding: 0;
    list-style-type: disc;
    padding-left: 20px;
}

ul li {
    margin-bottom: 10px;
} */


.body {
    /* background-color: #EFF1F2; */
    font-family: "Montserrat", sans-serif;
    color: #777;
}


.about-text {
    font-size: 16px;
    line-height: 25px;
    color: #777;
}

.highlight {
    font-weight: bold;
    color: #2980b9;
}

ul {
    margin: 20px 0;
    padding-left: 20px;
}

ul li {
    margin-bottom: 10px;
}