.certification-item img {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .certification-item p {
    text-align: center;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
  }
  