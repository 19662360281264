/* General Form Styles */
.form-container {
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); */
    font-family: 'Arial', sans-serif;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
    color: #333;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .form-group textarea {
    resize: vertical;
    height:150px;
  }
  
  .submit-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
  }
  .btn{
    background-color:var(--primary-color) ;
    color: white;
    font-size: 16px;
    font-weight: 600;
    font-family: Montserrat,sans-serif;
    height: 49.2px;
    width: 207.35px;
    padding: 15px 24px 15px 24px;
 }
 .btn:hover{
    background-color: black;
    color: white;
 }
  