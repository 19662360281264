

.contact-text-container {
    max-width: 800px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
}

.contact-text-container p {
    margin-bottom: 20px;
    font-size: 16px;
}

.contact-text-container strong {
    color: #2980b9;
}

.privacy-note {
    font-size: 14px;
    color: #777;
    margin-top: 15px;
}

