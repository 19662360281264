.image{
    background-image: url('./../Assets/bannerimage.jpg');
    background-size: cover;
    background-position: center;
    height: 330px; 
}

.contentdiv{
    background-color: rgba(0, 0, 0, 0.6);
}


.title{
    color: white;
    font-size: 36px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    line-height: 46px;
    text-decoration: none solid rgb(255,255,255);
}
