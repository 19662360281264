.aboutustittle{
 font-family: Montserrat,sans-serif;
 font-size: 30px;
 font-weight: 600;
 line-height: 42px;
 text-decoration: none solid rgb(51,51,51);
 text-align:center;
 text-transform: uppercase;
 color:#333333;   
}

.abouttext{
  font-family: "Open Sans",sans-serif;
  font-size: 16px;
  line-height: 25px;
  text-decoration: none solid rgb(119,119,119);
  color: #777777;
  word-wrap: break-word;
  text-align: center;
}

.abc{
  font-family: Montserrat,sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  text-decoration: none solid rgb(51,51,51);
  text-transform: uppercase;
  color:#333333;
}