@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family:"Open Sans",sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-color: #F49100;
  --secondary-color: #2ecc71;
  --font-size-base: 16px;
  --header-height: 60px;
  --bg-light: #f0f0f0;
  --spacing-small: 8px;
  --spacing-large: 24px;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
